export enum AuctionLotActivityStatus {
    Inactive = 1,
    Active = 2,
    Withdrawn = 3,
    Removed = 4,
    Sold = 5,
    PassedIn = 6,
    NotSold = 7,
    StartDelayed = 8,
    NowSelling = 9,
    Paused = 10,
    ResumeDelayed = 11,
    FinishDelayed = 12,
    StartDelayPaused = 13,
    WithdrawnNotSold = 14,
}
