export enum AuctionStatus {
    Draft = 0,
    Pending = 1,
    Published = 2,
    InEdit = 3,
    Finalised = 4,
    Ready = 5,
    InProgress = 6,
    Finished = 7,
    Removed = 8,
    Cancelled = 9,
    Paused = 10,
    Closed = 11,
}

export enum AuctionStatusValues {
    Published = 2,
    Finalised = 4,
    Ready = 5,
    Running = 6,
    Finished = 7,
    Removed = 8,
    Cancelled = 9,
    Paused = 10,
    Closed = 11,
}
